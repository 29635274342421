import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "The Cultural Mic Drop: Harnessing the Power of When",
  date: "2018-10-02",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20181002_1.jpg",
  path: "2018-10-02-Cultural-Mic-Drop",
  teaserText: "It takes precise intuition to own a conversation these days. In a sea of re-tweets and short-lived news cycles, there is power in making a clear, definitively timed statement."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="The Cultural Mic Drop: Harnessing the Power of When" description="It takes precise intuition to own a conversation these days. In a sea of re-tweets and short-lived news cycles, there is power in making a clear, definitively timed statement." author='Roberto Ramos' keywords={['Robots']} />
      <ImageLandscape imageSrc="20181002_1a.jpg" caption="" />
      <MDXTag name="p" components={components}>{`It takes precise intuition to own a conversation these days. In a sea of re-tweets and short-lived news cycles, there is power in making a clear, definitively timed statement. The art of “when” is more important than ever as consumers who are time poor and experience hungry are looking for meaningful things that stand out. For brands that want to truly engage, it means becoming more creatively intuitive, coveted and time-sensitive, plus relevantly bold in everything they do. Thus, the mic drop, an experience so staggeringly powerful that the performer drops the mic to stunned silence from the audience.`}</MDXTag>
      <ImageLandscape imageSrc="20181002_2.jpg" caption="Supreme" />
      <MDXTag name="p" components={components}>{`Stellar cultural voices and brands that own the conversation today know when to bring an idea to life. For instance, during this time when town criers warn of a retail apocalypse, streetwear brand Supreme has cool kids lined up waiting outside its stores for the latest timed drop or seeking out Supreme-branded metro cards and newspapers. Supreme’s subversive school of marketing delivers on two powerful elements of when: first, the ability to change the conversation through surprise; and second, in an era of ongoing duplication and manipulation, it delivers something coveted for a limited amount of time.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The artful mastery of when also happens when a cultural catalyst pays close attention to the underlying shifts in the zeitgeist. Apple did this in the 80s with its nascent sense of individual creativity by extolling the power of thinking differently in the face of the rise of corporate-driven personal computers. The brand has continued owning the conversation by adopting a secretive, drop-like mentality around its design and product launches.`}</MDXTag>
      <ImageLandscape imageSrc="20181002_3.jpg" caption="Nike" />
      <MDXTag name="p" components={components}>{`A recent branded mic drop was Nike’s ads supporting Colin Kapernick. The brand, confident in its rebel’s (albeit its behemoth corporate status) history and DNA, lived up to its legacy “Just do it” tagline and went all-in to make a statement supporting Kapernick’s social stance. Stealth-like in its approach, Nike simply posted the ad with no further explanation than the lines, “Believe in Something. Even if it means sacrificing everything.” In doing so, Nike brand managers knew that they would run the risk of offending many, yet powerfully recalibrate a more meaningful relationship with a younger, more socially-focused consumer.`}</MDXTag>
      <ImageLandscape imageSrc="20181002_4.jpg" caption="Beyoncé’s Lemonade" />
      <MDXTag name="p" components={components}>{`Besides having something powerful to stay, the mic drop and its brethren, the drop, challenge social media’s frantic always-on culture. When Beyoncé secretly launches a visual album like Lemonade overnight, she is rewriting the rulebook, favoring elusiveness versus the Kardashian-esque school of overexposure. Beyoncé makes a statement so clear and relevant that she hijacks all other conversations. There is an aura of boldness around the act that elicits desire.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For a generation used to consuming things on their own terms, the drop also creates a sense of urgency around access: being the first ones to experience something while it’s taking place. We see this in the popularity of festivals, wanderlust-fueled travel, capsule collections and the appeal of ephemerally-timed Snapchat stories.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The drop can also serve as a break or edit from the sensory overload around products and branding messages. Subscription boxes like Blue Apron and Birchbox thrive by giving individuals both a monthly surprise while canceling out the noise of overtly merchandised retail. They also give consumers welcome predictability in an increasingly uncertain world.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The power of the drop is ultimately focused on creating a system of extreme fluidity around cultural conversations. The potential is vastly expanding in this realm as AI technologies now allow us to go wider and deeper in terms of canvassing popular sentiment. Savvy brand leaders fluidly weave the power of high tech with good-old human intuition, designing brand experiences that are so relevant and timely that they build powerful emotional and cultural equity for the brand. And in an era of constant disruption, being hardwired to intelligently disrupt through emotions is the most powerful of all drops.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    